import React, { useState, useEffect,useRef } from 'react'
import { Button } from "./ui/button"
import { Progress } from "./ui/progress"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { Input } from "./ui/input"
import { LayoutDashboard, User, Lightbulb, FileText, Image, HelpCircle, HeadphonesIcon, ChevronDown, ChevronUp, Search, ChevronRight, MessageCircle, CheckCircle, ChevronLeft, Play } from 'lucide-react'
import { CustomSelect } from './CustomSelect'
import Sidebar from './sidemenu'
import { getUserInfo, sendSupportMessage, getRecentGenerations, getUsageStats, getRecentActivities, clearRecentActivities } from '../lib/apiCalls'
import { useNavigate } from 'react-router-dom'


const ContentCard = ({ src, title, description, createdAt, type }) => {
  const isVideoFile = src?.toLowerCase().match(/\.(mp4|webm|ogg|mov)$/);

  return (
    <div className="flex-shrink-0 w-64 bg-white rounded-lg shadow-md overflow-hidden">
      {src && (
        <div className="relative w-full h-32">
          {isVideoFile ? (
            <>
              <video 
                src={src} 
                className="w-full h-32 object-cover" 
                controls
              />
              <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-2 shadow-md">
                <Play size={24} className="text-purple-600" />
              </button>
            </>
          ) : (
            <img 
              src={src} 
              alt={title} 
              className="w-full h-32 object-cover"
            />
          )}
        </div>
      )}
      <div className={`p-4 ${type === 'Posts' && 'h-[200px]'}`}>
        {title && <h3 className="font-semibold text-lg mb-2 h-[10%]">{title}</h3>}
        <p className="text-sm text-gray-600 mb-2 h-[80%] line-clamp-6">
          {description}
        </p> 
        <p className="text-xs text-gray-400 h-[10%]">
          {new Date(createdAt).toLocaleString()}
        </p>
      </div>
    </div>
  );
};

const StatCard = ({ metric, count, growth, trend }) => {

  console.log("Count: ", count, "\nGrowth: ", growth)

  const navigate= useNavigate()
  const Goto = ()=>{
    if(metric==="Concepts Generated"){
      navigate("/concept-generation")
    }else if(metric==="Posts Generated"){
      navigate("/post-generation")
    }else if(metric==="Medias Captioned"){
      navigate("/media-captioning")
    }
  }
  return(
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-lg font-semibold mb-2 font-['Cormorant_Garamond',serif] ">{metric}</h3>
    <div className="flex items-end justify-between">
      <div>
        <div className={`flex justify-start gap-2 `}>
          <p className={`text-4xl font-bold `}>{count}</p> 
          <div className={`flex items-center ${growth >= 0 ? 'text-green-500' : 'text-red-500'} `}>
            {growth >= 0 ? (
              <ChevronUp size={20} className="mr-1" />
            ) : (
              <ChevronDown size={20} className="mr-1" />
            )}
            <span className={`text-lg font-semibold ${(!growth ||growth === 0)?'line-through':''} `}>{Math.abs(growth)>0?`${Math.abs(growth)}%`:Math.abs(growth)}</span>
          </div>
        </div>
        
        <p className="text-sm text-gray-500">Compared to last month</p>
      </div>
      <div className={`flex items-center ${growth >= 0 ? 'text-green-500' : 'text-red-500'}`}>
        <img 
          src={(!growth || growth===0) ? '/trendzero.png' : growth >= 0 ? '/trendup.png' : '/trenddown.png'} 
          alt={(!growth || growth === 0)? 'Trend Flat' :growth >= 0 ? 'Trend up' : 'Trend down'} 
          className="w-12 h-12 mr-2"
        />
      </div>
    </div>
    <Button className="w-full mt-4 bg-blue-600 text-white hover:bg-blue-700 rounded-full" onClick={Goto}>
      {metric === 'Concepts Generated' ? 'Generate Concept' : 
       metric === 'Posts Generated' ? 'Generate Post' : 'Generate Captions'}
    </Button>
  </div>
)};

export default function Dashboard() {
  const [email, setEmail] = useState('faithawokunle1@gmail.com')
  const [message, setMessage] = useState('')
  const [messageSent, setMessageSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [recentActivities, setRecentActivities] = useState([])
  const [recentGenerations, setRecentGenerations] = useState({
    concepts_generated: { mainstream: [], offstream: [] },
    posts_generated: [],
    media_captioned: []
  })
  const [usageStats, setUsageStats] = useState([])
  const [activeTab, setActiveTab] = useState('concepts')
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth())
  const navigate= useNavigate()

  const [profileCompletion , setProfileCompletion] = useState(0)

  useEffect(() => {
    fetchRecentGenerations()
    handelGetRecentActivities()
    fetchUserInfo();
  }, [])



  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo();
      console.log("user: ", response.data);

      if (response.success) {
        const { data } = response;
        setProfileCompletion(data.profile_completion);
      }

      if(response.status === 401){
        navigate('/login')
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Failed to fetch user info:', error);
    }
  };




  useEffect(() => {
    if (hasRecentGenerations(recentGenerations)) {
      fetchUsageStats(selectedMonth)
    }
  }, [selectedMonth, recentGenerations])

  const handelGetRecentActivities = async()=>{
    try { 
      const response = await getRecentActivities()
      console.log(response)
      if (response.success) {
        setRecentActivities(response.data.activites || [])
      } else {
        console.error('Failed to fetch recent activities')
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Error fetching recent generations:', error)
    }
  }

  const fetchRecentGenerations = async () => {
    try {
      const response = await getRecentGenerations('all')
      if (response.success) {
        console.log("\n\nGeneratoins: ", response.data, "\n\n")
        setRecentGenerations(response.data)
        
        if (hasRecentGenerations(response.data)) {
          fetchUsageStats(selectedMonth)
        }
      } else {
        console.error('Failed to fetch recent generations')
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Error fetching recent generations:', error)
    }
  }

  const fetchUsageStats = async (month) => {
    try {
      const response = await getUsageStats(month)
      console.log(response.data)
      if (response.success) {
        setUsageStats(response.data)
      } else {
        console.error('Failed to fetch usage stats')
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Error fetching usage stats:', error)
    }
  }

  const hasRecentGenerations = (data) => {
    return data.concepts_generated.mainstream.length > 0 ||
           data.concepts_generated.offstream.length > 0 ||
           data.posts_generated.length > 0 ||
           data.media_captioned.length > 0
  }

  const handleSendMessage = async () => {
    if (message.trim() !== '') {
      setIsLoading(true)
      setError(null)
      try {
        const response = await sendSupportMessage(message)
        if (response.success) {
          setMessageSent(true)
          setMessage('')
        } else {
          setError('Failed to send message. Please try again.')
        }
      } catch (error) {
        if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
        setError('An error occurred. Please try again later.')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleNewMessage = () => {
    setMessageSent(false)
    setError(null)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  }

  function getCurrentMonth() {
    const date = new Date()
    date.setMonth(date.getMonth() - 1);
    return date.toLocaleString('default', { month: 'long' })
  }

  function getLastSixMonths() {
    const months = []
    for (let i = 1; i < 7; i++) {
      const date = new Date()
      date.setMonth(date.getMonth() - i)
      months.push(date.toLocaleString('default', { month: 'long' }))
    }
    return months
  }

  const RenderContentSection = (content, type) => {
    const scrollContainerRef = useRef(null);
  
    // Function to scroll left
    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: -300, // Adjust this value for how much you want to scroll
          behavior: 'smooth',
        });
      }
    };
  
    // Function to scroll right
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: 300, // Adjust this value for how much you want to scroll
          behavior: 'smooth',
        });
      }
    };
  
    if (content.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-48">
          <img src="/No-Results.png" alt="No results" className="w-[140px] h-[113.5px] mb-4" />
          <h3 className="text-[20px] font-semibold font-['Cormorant_Garamond',serif]">No {type} yet!</h3>
          <Button className="h-[40px] width-auto rounded-full bg-white border border-blue-500 hover:bg-blue-700 hover:text-white text-blue-500 px-8 mt-2">
            Generate {type}
          </Button>
        </div>
      );
    }
  
    return (
      <div className="relative">
        {/* Scrollable content container */}
        <div className="flex overflow-x-auto space-x-4 pb-4" ref={scrollContainerRef}>
          {content.map((item, index) => (
            <ContentCard
              key={index}
              src={item.content_url || null}
              title={item.name || item.title}
              description={item.concept?.description || item.content}
              createdAt={item.concept?.created_at || item.created_at}
              type={type}
            />
          ))}
        </div>
  
        {/* Left scroll button */}
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
          onClick={scrollLeft}
        >
          <ChevronLeft size={24} />
        </button>
  
        {/* Right scroll button */}
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
          onClick={scrollRight}
        >
          <ChevronRight size={24} />
        </button>
      </div>
    );
  };

  return (
    <div className="flex min-h-screen bg-[#FCFCFC] font-['Inter',sans-serif]">
      <Sidebar />
      <main className="flex-1 p-4 flex flex-col space-y-4 h-screen overflow-y-auto ml-[377px]">
        <header className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Dashboard</h1>
        </header>

        <div className="flex gap-4">
          <div className="min-w-[306px] flex flex-col justify-center items-center bg-white rounded-xl p-6 shadow-sm">
            <h2 className="font-medium mb-5 text-center">Complete your user info for better results</h2>
            <div className="flex justify-between items-center mb-2">
              <Progress value={profileCompletion} className="w-[230px] border h-3 bg-white" />
              <span className="ml-2">{profileCompletion}%</span>
            </div>
            <Button variant="outline" className="rounded-full mt-5 text-blue-600 border-blue-600 hover:bg-blue-500 hover:text-white" onClick={()=>{navigate("/user-info/true")}}>
              Edit Profile
            </Button>
          </div>

          <div className="bg-white border-[#F5F5F5] rounded-[10px] min-w-[643px] p-6">
            {hasRecentGenerations(recentGenerations) ? (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-['Cormorant_Garamond',serif]">Usage stats</h2>
                  <CustomSelect
                    options={getLastSixMonths()}
                    value={selectedMonth}
                    onChange={setSelectedMonth}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  {usageStats.map((stat, index) => (
                    <StatCard
                      key={index}
                      metric={stat.Metric}
                      count={stat.Count}
                      growth={stat["Growth (%)"]}
                      trend={stat["Growth Trend"]}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <h2 className="text-xl font-['Cormorant_Garamond',serif] mb-4 bg-[#EAEFFD] h-[58px] rounded-[10px] flex justify-center items-center text-[20px] font-[400]">Get started with Generating Content</h2>
                <div className="grid grid-cols-3 gap-4">
                  <FeatureCard
                    title="Kickstart your creative process by generating content ideas"
                    buttonText="Generate Concept"
                    icon='placeholder-img.png'
                  />
                  <FeatureCard
                    title="Craft compelling posts tailored to your audience"
                    buttonText="Generate Post"
                    icon='placeholder-img.png'
                  />
                  <FeatureCard
                    title="Create captivating video captions that engage your viewers"
                    buttonText="Caption Video"
                    icon='placeholder-img.png'
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-sm">
          <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold font-['Cormorant_Garamond',serif]">Content Library</h2>
              <TabsList className="bg-transparent">
                <TabsTrigger 
                  value="concepts" 
                  className="px-4 py-2 text-sm focus:outline-none transition-colors rounded-none duration-200 border-b-2 border-transparent data-[state=active]:border-blue-600"
                >
                  Concepts
                </TabsTrigger>
                
                <TabsTrigger 
                  value="posts" 
                  className="px-4 py-2 text-sm focus:outline-none transition-colors duration-200 rounded-none border-b-2 border-transparent data-[state=active]:border-blue-600"
                >
                  Posts
                </TabsTrigger>
                <TabsTrigger 
                  value="captions" 
                  className="px-4 py-2 text-sm focus:outline-none transition-colors rounded-none duration-200 border-b-2 border-transparent data-[state=active]:border-blue-600"
                >
                  Captions
                </TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value="concepts" className="mt-4">
              {RenderContentSection([...recentGenerations.concepts_generated.mainstream, ...recentGenerations.concepts_generated.offstream], 'Concepts')}
            </TabsContent>
            <TabsContent value="posts" className="mt-4">
              {RenderContentSection(recentGenerations.posts_generated, 'Posts')}
            </TabsContent>
            <TabsContent value="captions" className="mt-4">
              {RenderContentSection(recentGenerations.media_captioned, 'Captions')}
            </TabsContent>
          </Tabs>
        </div>

        <div className="flex gap-4">
          {recentActivities.length > 0 ? (
            <div className="bg-white flex-2 rounded-xl flex-grow p-6 min-w-[636px] w-auto h-[277px] shadow-sm">
              {/* Fixed header */}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold font-['Cormorant_Garamond',serif]">Recent Activities</h2>
                <button className="text-red-500 hover:text-red-700" onClick={async () => { 
                  await clearRecentActivities();
                  await handelGetRecentActivities()  
                }}>
                  Delete all
                </button>
              </div>
            
              {/* Scrollable activity list */}
              <div className="overflow-y-auto h-[calc(100%-64px)]">
                {recentActivities.map((activity, index) => (
                  <div key={index} className="mb-4 last:mb-0">
                    <h3 className="text-lg font-semibold">{activity.type}</h3>
                    <p className="text-gray-600">{activity.message}</p>
                    <p className="text-sm text-gray-400">{formatDate(activity.created_at)}</p>
                  </div>
                ))}
              </div>
            </div>
          
          ) : (
            <div className="bg-white flex-2 rounded-xl flex-grow p-6 min-w-[636px] w-auto h-[277px] shadow-sm text-center flex flex-col justify-center items-center">
              <div className='text-center w-[60%]'>
                <h3 className="text-lg w-full font-semibold mb-2 font-['Cormorant_Garamond',serif] ">Read the User Guide</h3>
                <p className="text-gray-600 w-full mb-4">Discover how to use each feature to its full potential.</p>
                <Button variant="outline" className="w-full rounded-full text-blue-600 border-blue-600 hover:bg-blue-50">Explore Now!</Button>
              </div>
            </div>
          )}

          <div className="bg-white flex-1 rounded-xl flex flex-col justify-center items-center flex-grow p-6 shadow-sm w-auto min-w-[308px]">
            {!messageSent ? (
              <>
                <div className="flex flex-col w-[50%] items-center mb-4">
                  <MessageCircle className="text-blue-600 mr-2" size={48} />
                  <h3 className="text-lg font-semibold font-['Cormorant_Garamond',serif] ">Send us a message!</h3>
                </div>
                <p className="text-sm text-gray-600 w-[60%] text-center mb-4">Got any issue? Send us a mail and we'll get back to you</p>
                <div className="relative mt-3 w-[90%]">
                  <Input 
                    placeholder="Your message here..." 
                    className="pr-10 rounded-lg"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={isLoading}
                  />
                  <Button 
                    className="absolute right-0 top-0 bottom-0 rounded-full" 
                    variant="ghost"
                    onClick={handleSendMessage}
                    disabled={isLoading}
                  >
                    <ChevronRight size={20} className="text-blue-600" />
                  </Button>
                </div>
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              </>
            ) : (
              <>
                <div className="flex flex-col w-[50%] items-center mb-4">
                  <CheckCircle className="text-green-600 mr-2" size={48} />
                  <h3 className="text-lg font-semibold">Message Sent!</h3>
                </div>
                <p className="text-sm text-gray-600 w-[60%] text-center mb-4">Thanks for your message! We will get back to you soon.</p>
                <Button 
                  className="mt-3 rounded-full bg-blue-600 text-white hover:bg-blue-700"
                  onClick={handleNewMessage}
                >
                  Send new Message
                </Button>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  )
}

function FeatureCard({ title, buttonText, icon, link }) {
  
  return (
    <div className="bg-white rounded-[10px] p-4 border border-[#E5E7EB] flex flex-col items-center">
      <img src={icon} alt="Feature icon" className="w-20 h-20 mb-4" />
      <h3 className="font-medium text-sm text-center mb-4">{title}</h3>
      <Button 
        variant="outline" 
        className="w-full rounded-full text-[#2D5BFF] border-[#2D5BFF] hover:bg-[#2D5BFF] hover:text-white text-xs py-2 px-4"
        
      >
        {buttonText}
      </Button>
    </div>
  )
}
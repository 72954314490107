import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Button } from "../../PageComponents/ui/button";
import { useNavigate } from 'react-router-dom';
import DashboardComponent from '../../PageComponents/dashboard';

export default function Dashboard() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user) {
  //     logout()
  //   }
  // }, [user, navigate]);

  // if (!user) {
  //   return null;
  // }

  return (
    // <div className="min-h-screen bg-gray-100 p-8">
    //   <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
    //     <h1 className="text-3xl font-bold mb-4 font-['Cormorant_Garamond',serif]">Welcome to your Dashboard</h1>
    //     <p className="mb-4">You are logged in as: {user.email}</p>
    //     <Button onClick={logout} className="bg-[#2D5BFF] hover:bg-blue-600 text-white">
    //       Logout
    //     </Button>
    //   </div>
    // </div>
    <DashboardComponent />
  );
}
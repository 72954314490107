import React, { createContext, useState, useContext, useCallback } from 'react';
import { getUserInfo } from '../lib/apiCalls';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await getUserInfo();
      if (response.success) {
        setUserInfo(response.data);
        return response.data;
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Failed to fetch user info:', error);
    }
    return null;
  }, []);

  return (
    <UserContext.Provider value={{ userInfo, fetchUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserInfo = () => useContext(UserContext);
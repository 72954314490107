import React, { useState, useRef, useEffect, useCallback } from 'react';
import Sidebar from './sidemenu';
import { HelpCircle, ChevronDown, ImageIcon, Play, Copy, Download, Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import Tooltip from './tooltip';
import { usePosts } from '../context/PostContext';
import { Button } from './ui/button';
import { useUserInfo } from '../context/RefreshContext';
import ErrorPopup from './ErrorPopup';
import PostCard from './recent-post-card';
import axios from 'axios';

// Centralized error handling function
const handleError = (error, setError, setShowErrorPopup, setErrorPopupConfig) => {
  if (error.response?.status === 401) {
    localStorage.removeItem('user');
    window.location.href = '/login';
    return;
  }

  let errorMessage = 'An unexpected error occurred. Please try again.';
  let isPaymentRequired = false;

  if (error.response) {
    if (error.response.status === 402) {
      errorMessage = 'Out of credits.';
      isPaymentRequired = true;
    } else if (error.response.data?.message) {
      errorMessage = error.response.data.message;
    }
  }

  setError(errorMessage);
  setShowErrorPopup(true);
  setErrorPopupConfig({
    message: errorMessage,
    link: isPaymentRequired ? '/recharge' : ''
  });

  // Hide error popup after 5 seconds
  setTimeout(() => setShowErrorPopup(false), 5000);
};


const MediaItem = ({ src, onDelete }) => (
  <div className="border border-[#F5F5F5] rounded-lg overflow-hidden mb-4">
    <div className="relative rounded-lg">
      <img src={src} alt="Generated post" className="w-full rounded-lg h-48 object-cover" />
      <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-2">
        <Play size={24} className="text-purple-600" />
      </button>
    </div>
    <div className="p-4">
      <div className="flex justify-start mt-2 space-x-2">
        <button className='p-1 border border-[#F5F5F5] rounded-lg' onClick={() => window.open(src, '_blank')}>
          <Download size={20} className="text-gray-400 hover:text-gray-600" />
        </button>
        <button className='p-1 border border-[#F5F5F5] rounded-lg' onClick={() => navigator.clipboard.writeText(src)}>
          <Copy size={20} className="text-gray-400 hover:text-gray-600" />
        </button>
        <button className='p-1 border border-[#F5F5F5] rounded-lg' onClick={onDelete}>
          <Trash2 size={20} className="text-gray-400 hover:text-gray-600" />
        </button>
      </div>
    </div>
  </div>
);



export default function PostGeneration() {
  const [style, setStyle] = useState('E-Commerce');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);
  const [mediaContext, setMediaContext] = useState('');
  const [topicDetails, setTopicDetails] = useState('');
  const [additionalInstructions, setadditionalInstructions] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupConfig, setErrorPopupConfig] = useState({ message: '', link: '' });
  const [newPostIds, setNewPostIds] = useState(new Set());
  const [styles, setStyles] = useState([]);
  
  const fileInputRef = useRef(null);
  const { recentPosts, addPost, deleteAllPosts, fetchRecentPosts } = usePosts();
  const { fetchUserInfo } = useUserInfo();

  useEffect(() => {
    fetchRecentPosts().catch(error => {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    });
  }, []);

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await axios.get('https://backend-individuals.fluffy-ai.com/api/styles', {
          headers: {
            'accept': 'application/json'
          },
          withCredentials: true
        });
        setStyles(response.data.styles);
      } catch (error) {
        handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
      }
    };

    fetchStyles();
  }, []);

  const isValidFileType = useCallback((file) => {
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
    return acceptedTypes.includes(file.type);
  }, []);

  const handleFiles = useCallback((files) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    
    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrls(prevUrls => [...prevUrls, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handleFileSelect = useCallback((event) => {
    const files = Array.from(event.target.files).filter(isValidFileType);
    handleFiles(files);
  }, [isValidFileType]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files).filter(isValidFileType);
    handleFiles(files);
  }, [isValidFileType]);

  const handleRemoveFile = useCallback((index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
    setCurrentPreviewIndex(prev => {
      if (prev >= index && prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  }, []);

  const handlePreviousPreview = useCallback(() => {
    setCurrentPreviewIndex(prev => (prev > 0 ? prev - 1 : previewUrls.length - 1));
  }, [previewUrls.length]);

  const handleNextPreview = useCallback(() => {
    setCurrentPreviewIndex(prev => (prev < previewUrls.length - 1 ? prev + 1 : 0));
  }, [previewUrls.length]);

  const handleGeneratePost = useCallback(async () => {
    setIsGenerating(true);
    setError(null);
    try {
      const newPost = await addPost(style, mediaContext, topicDetails, additionalInstructions, selectedFiles);
      await fetchUserInfo();
      setNewPostIds(prevIds => new Set(prevIds).add(newPost.id));
    } catch (error) {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    } finally {
      setIsGenerating(false);
    }
  }, [style, mediaContext, topicDetails, additionalInstructions, selectedFiles, addPost, fetchUserInfo]);

  const handleDeleteAllPosts = async () => {
    try {
      await deleteAllPosts();
      setNewPostIds(new Set());
    } catch (error) {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    }
  };

  return (
    <div className="flex min-h-screen bg-[#FCFCFC] font-sans">
      <Sidebar />
      <div className="flex-1 p-8 ml-[380px] h-screen overflow-y-scroll scrollbar-hidden">
        {showErrorPopup && (
          <ErrorPopup 
            message={errorPopupConfig.message}
            link={errorPopupConfig.link}
          />
        )}
        <div className="w-auto mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold font-['Cormorant_Garamond',serif]">Post Generation</h1>
            <Tooltip 
              content="Generate a new post based on your inputs" 
              userGuidePath="www.example.com/user-guide"
            />
          </div>
          
          <div className="bg-white rounded-lg p-6">
            {/* Style selection */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm font-medium text-gray-700">
                  Style
                  <Tooltip 
                    content="Choose the style of your post" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
              </div>
              <div className="relative">
                <select
                  value={style}
                  onChange={(e) => setStyle(e.target.value)}
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-400 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  {styles.map(st=>(
                    <option value={st.value}>{st.label}</option>
                  ))}
                  {/* <option value='E-Commerce'>E-Commerce</option>
                  <option value='Social 1'>Social 1</option>
                  <option value='Social 2'>Social 2</option> */}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDown size={20} />
                </div>
              </div>
            </div>
            
            {/* Media upload */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm flex gap-2 items-center font-medium text-gray-700">
                  Media
                  <Tooltip 
                    content="Upload your media files here (JPEG, JPG, PNG, WebP only)" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
              </div>
              <div 
                className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center cursor-pointer hover:border-gray-400 relative"
                onClick={() => fileInputRef.current.click()}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input 
                  type="file" 
                  ref={fileInputRef} 
                  onChange={handleFileSelect} 
                  className="hidden" 
                  accept="image/jpeg,image/jpg,image/png,image/webp"
                  multiple
                />
                {previewUrls.length > 0 ? (
                  <div className="relative">
                    <img src={previewUrls[currentPreviewIndex]} alt="Preview" className="w-full h-48 object-cover rounded-lg" />
                    <button 
                      className="absolute top-2 right-2 bg-white rounded-full p-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFile(currentPreviewIndex);
                      }}
                    >
                      <Trash2 size={20} className="text-red-500" />
                    </button>
                    {previewUrls.length > 1 && (
                      <>
                        <button 
                          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white rounded-full p-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreviousPreview();
                          }}
                        >
                          <ChevronLeft size={20} className="text-gray-600" />
                        </button>
                        <button 
                          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white rounded-full p-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNextPreview();
                          }}
                        >
                          <ChevronRight size={20} className="text-gray-600" />
                        </button>
                      </>
                    )}
                    <p className="mt-2 text-sm text-gray-500">{currentPreviewIndex + 1} / {previewUrls.length}</p>
                  </div>
                ) : (
                  <>
                    <ImageIcon className="mx-auto text-gray-400 mb-4" size={48} />
                    <p><span className="text-blue-600 underline">Click</span> to upload or Drag and Drop files here</p>
                    <p className="text-sm text-gray-500 mt-2">Allowed formats: JPEG, JPG, PNG, WebP</p>
                  </>
                )}
              </div>
            </div>
            
            {/* Media context */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm font-medium text-gray-700">
                  Provide media context <span className="text-purple-400">(optional)</span>
                  <Tooltip 
                    content="Describe the context of your media" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
                <span className="text-sm text-gray-400">{mediaContext.split(' ').length}/250 words</span>
              </div>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                placeholder="Like where the photo was taken, who are the participants, etc"
                value={mediaContext}
                onChange={(e) => setMediaContext(e.target.value)}
              ></textarea>
            </div>
            
            {/* Post topic/details */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm font-medium text-gray-700">
                  Post Topic/Details <span className="text-purple-400">(optional)</span>
                  <Tooltip 
                    content="Provide details about your post topic" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
                <span className="text-sm text-gray-400">{topicDetails.split(' ').length}/250 words</span>
              </div>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                placeholder="e.g I want a post about my childhood story..."
                value={topicDetails}
                onChange={(e) => setTopicDetails(e.target.value)}
              ></textarea>
            </div>
            
            {/* Target audience */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm font-medium text-gray-700">
                  Additional Instructions <span className="text-purple-400">(optional)</span>
                  <Tooltip 
                    content="Describe your target audience" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
                <span className="text-sm text-gray-400">{additionalInstructions.split(' ').length}/250 words</span>
              </div>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                placeholder="e.g. Millennials interested in sustainable living"
                value={additionalInstructions}
                onChange={(e) => setadditionalInstructions(e.target.value)}
              ></textarea>
            </div>
            
            {/* Generate button */}
            <div className='w-full h-auto flex justify-end'>
              <Button
                onClick={handleGeneratePost}
                className="w-auto px-9 bg-[#9333EA] text-white py-3 rounded-full hover:bg-[#7E22CE] transition duration-300"
                disabled={isGenerating}
              >
                {isGenerating ? 'Generating...' : 'Generate $0.10'}
              </Button>
            </div>
            
            {error && <p className="text-red-500 mt-4">{error}</p>}
            
          </div>
        </div>
      </div>
      
      {/* Recent posts */}
      <div className="flex-1 bg-white p-6 border-none mr-10 h-screen overflow-y-scroll scrollbar-hidden">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold font-['Cormorant_Garamond',serif]">Recent</h2>
          {recentPosts.length > 0 && (
            <button 
              className="text-red-500 hover:text-red-700" 
              onClick={handleDeleteAllPosts}
            >
              Clear all
            </button>
          )}
        </div>
        
        {recentPosts.length > 0 ? (
          recentPosts?.map((post) => (
            <PostCard 
              key={post.id}
              id={post.id}
              title={post.title} 
              content={post.content} 
              contentUrl={post.content_url}
              isNew={newPostIds.has(post.id)}
            />
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-gray-400">
            <img src="/No-Results.png" alt="Concept" className="mb-4 w-[140px] h-[119px]" />
            <p>Nothing here yet!</p>
          </div>
        )}
      </div>
    </div>
  );
}
import React, { useState, useRef, useEffect } from 'react';
import { HelpCircle } from 'lucide-react';

const Tooltip = ({ content, userGuidePath }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const timeoutRef = useRef(null);

  const showTooltip = () => {
    clearTimeout(timeoutRef.current);
    setIsVisible(true);
  };

  const hideTooltip = () => {
    timeoutRef.current = setTimeout(() => setIsVisible(false), 300);
  };

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewport = {
        width: window.innerWidth || document.documentElement.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight,
      };

      let top = triggerRect.top - tooltipRect.height - 10;
      let left = triggerRect.left + (triggerRect.width / 2) - (tooltipRect.width / 2);

      if (top < 0) {
        top = triggerRect.bottom + 10;
      }
      if (left < 10) {
        left = 10;
      }
      if (left + tooltipRect.width > viewport.width - 10) {
        left = viewport.width - tooltipRect.width - 10;
      }

      setPosition({ top, left });
    }
  }, [isVisible]);

  // Open the provided link in a new tab without using the base URL
  const handleClick = (e) => {
    e.preventDefault();
    const fullUrl = userGuidePath.startsWith('http') ? userGuidePath : `https://${userGuidePath}`;
    window.open("/how-to-use", '_blank', 'noopener,noreferrer');
  };
  

  return (
    <div className="relative inline-block">

      <button
        onClick={handleClick}
        ref={triggerRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        className="cursor-pointer"
      >
        <HelpCircle className="text-purple-500" size={20} />
      </button>
      
      {isVisible && (
        <div
          ref={tooltipRef}
          className="fixed z-50 p-3 text-sm bg-white rounded-lg shadow-lg max-w-xs"
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <div className="flex items-start space-x-2">
            <HelpCircle className="text-purple-500 flex-shrink-0 mt-0.5" size={16} />
            <div>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <button
                onClick={handleClick}
                className="block mt-2 text-purple-600 hover:underline text-left"
              >
                Learn more...
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

import React, { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { LayoutDashboard, User, Lightbulb, FileText, Image, HelpCircle, HeadphonesIcon, ChevronDown } from 'lucide-react';
import { useHandles } from '../context/HandleContext';
import { useUserInfo } from '../context/RefreshContext';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setHandles, setRelatedHandles } = useHandles();
  const { userInfo, fetchUserInfo } = useUserInfo();

  const updateHandles = useCallback((data) => {
    if (data.your_handles) {
      setHandles(data.your_handles.map(handle => ({
        handle: handle.reference,
        platform: handle.type
      })));
    }

    if (data.related_handles) {
      setRelatedHandles(data.related_handles.map(handle => ({
        handle: handle.reference,
        platform: handle.type
      })));
    }
  }, [setHandles, setRelatedHandles]);

  useEffect(() => {
    const initUserInfo = async () => {
      const data = await fetchUserInfo();
      if (data) {
        updateHandles(data);
      }
    };

    initUserInfo();
  }, [fetchUserInfo, updateHandles]);

  const goToWallet = () => {
    navigate('/wallet-recharge');
  };

  const navItems = [
    { icon: <LayoutDashboard size={20} />, text: "Dashboard", href: "/dashboard" },
    { icon: <User size={20} />, text: "User Info", href: "/user-info" },
    { icon: <Lightbulb size={20} />, text: "Concept Generation", href: "/concept-generation" },
    { icon: <FileText size={20} />, text: "Post Generation", href: "/post-generation" },
    { icon: <Image size={20} />, text: "Video Captioning", href: "/media-captioning" }
  ];

  const helpItems = [
    { icon: <HelpCircle size={20} />, text: "How to use", href: "/how-to-use" },
    { icon: <HeadphonesIcon size={20} />, text: "Help and support", href: "/help-and-support" }
  ];


  

  return (
    <aside className="w-64 min-w-[360px] h-[97vh] fixed pl-[40px] pr-[20px] box-border border border-[#F7F7F7] bg-white text-black p-4 m-4 rounded-3xl flex flex-col">
      <div className="mb-8 flex items-center space-x-2">
        <img src="/favicon.png" className='w-[30px] h-[30px]' alt="Fluffy logo" />
        <span className="text-[36px] font-[700] font-['Cormorant_Garamond',serif] ">Fluffy</span>
      </div>
      <nav className="flex-grow space-y-1">
        {navItems.map((item, index) => (
          <NavItem 
            key={index}
            icon={item.icon} 
            text={item.text} 
            href={item.href}
            active={location.pathname === item.href}
          />
        ))}
      </nav>

      <nav className="flex-grow mt-10">
        {helpItems.map((item, index) => (
          <NavItem 
            key={index}
            icon={item.icon} 
            text={item.text} 
            href={item.href}
            active={location.pathname === item.href}
          />
        ))}
      </nav>
      <div className="mt-16 relative">
        <img src="/wallet.png" alt="Wallet" className='w-[118px] h-[118px] absolute right-1 -top-2' />
        {userInfo?.current_balance > 0 && <p className="text-[16px] text-[#676767] mb-2">Wallet balance</p>}
        <h2 className="text-[40px] font-bold mb-3">${userInfo ? userInfo.current_balance.toFixed(2) : '0.00'}</h2>
        <div className="text-[16px] text-[#676767] mb-2">{(userInfo?.current_balance === 0 || !userInfo?.current_balance)  && 'Recharge your wallet to start generating'}</div>
        <Button className="w-full h-[50px] bg-blue-600 hover:bg-blue-700 text-white rounded-full" onClick={goToWallet}>Recharge</Button>
      </div>
      <div className="mt-20 relative">
        <Input 
          value={userInfo ? userInfo.email : ''}
          className="text-[#1E293B] bg-none border rounded-lg pr-8"
          readOnly
        />
        <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
      </div>
    </aside>
  );
}

function NavItem({ icon, text, href, active }) {
  return (
    <a
      href={href}
      className={`flex items-center space-x-2 px-3 py-2 rounded-lg text-sm font-['Cormorant_Garamond',serif] ${
        active ? 'bg-[#112863] text-white py-2' : 'text-gray-400 hover:bg-gray-100 hover:text-gray-700'
      }`}
    >
      {icon}
      <span>{text}</span>
    </a>
  );
}
import React from 'react'
import UserInfoPage from '../../PageComponents/user-info'

const UserInfo = () => {
  return (
    <UserInfoPage />
    // <div>User info</div>
  )
}

export default UserInfo
import React, { useRef, useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { HelpCircle, X, ChevronDown } from 'lucide-react';
import Tooltip from './tooltip';

const socialMediaPlatforms = [
  { name: 'instagram', icon: '/instagram.png', disabled: false },
  { name: 'facebook', icon: '/facebook.png', disabled: true },
  { name: 'twitter', icon: '/x.png', disabled: true },
  { name: 'linkedIn', icon: '/linkedin.png', disabled: true },
  // Add more platforms as needed
];

function HandleInputs({ label, handles, addHandle, removeHandle, updateHandle, isEditing, onBlur }) {
  const inputRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value.trim() !== '') {
        addHandle('', 'instagram');
        setCurrentEditIndex(handles.length);
        setShowDropdown(true);
        setTimeout(() => {
          const inputs = inputRef.current.querySelectorAll('input');
          if (inputs[index + 1]) {
            inputs[index + 1].focus();
          }
        }, 0);
        // onBlur(); // Update user info when a new handle is added
      }
    }
  };

  const handlePlatformSelect = (platform, index) => {
    if (!platform.disabled) {
      updateHandle(index, handles[index].handle, platform.name);
      setShowDropdown(false);
      setCurrentEditIndex(null);
      // onBlur(); // Update user info when platform is changed
    }
  };

  const handleInputBlur = () => {
    // onBlur(); // Update user info when input loses focus
  };

  return (
    <div className="space-y-2" ref={inputRef}>
      <div className="flex items-center gap-3">
        <label className="text-lg text-gray-700 font-medium">{label}</label>
        <Tooltip content="tooltip testing" userGuidePath="www.google.com" />
      </div>
      <div className="flex flex-wrap gap-2">
        {handles?.map((handle, index) => (
          <div key={index} className="flex px-4 items-center h-[45px] w-auto bg-white border border-gray-200 rounded-full overflow-visible relative">
            <div 
              className="cursor-pointer flex items-center" 
              onClick={() => {
                if (isEditing) {
                  setCurrentEditIndex(index);
                  setShowDropdown(!showDropdown);
                }
              }}
            >
              <img src={socialMediaPlatforms.find(p => p.name === handle.platform)?.icon || '/default-icon.png'} alt="" className='w-[20px] h-[16px]' />
              {isEditing && <ChevronDown size={12} className="ml-1" />}
            </div>
            <Input 
              value={handle.handle} 
              onChange={(e) => updateHandle(index, e.target.value, handle.platform)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onBlur={handleInputBlur}
              className="border-none focus:ring-0 pl-2"
              readOnly={!isEditing}
            />
            {isEditing && (
              <Button 
                onClick={() => {
                  removeHandle(index);
                  // onBlur(); // Update user info when a handle is removed
                }} 
                variant="ghost" 
                className="p-2 hover:bg-transparent"
              >
                <X size={16} className="text-gray-400" />
              </Button>
            )}
            {showDropdown && currentEditIndex === index && (
              <div className="absolute top-full left-0 mt-1 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                {socialMediaPlatforms.map((platform) => (
                  <div 
                    key={platform.name}
                    className={`flex items-center px-4 py-2 ${platform.disabled ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-100 cursor-pointer'}`}
                    onClick={() => !platform.disabled && handlePlatformSelect(platform, index)}
                  >
                    <img src={platform.icon} alt={platform.name} className={`w-4 h-4 mr-2 ${platform.disabled ? 'opacity-50' : ''}`} />
                    <span className={platform.disabled ? 'text-gray-400' : ''}>
                      {platform.disabled ? 'Coming soon' : platform.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {isEditing && (
        <Button 
          onClick={() => {
            addHandle('', 'instagram');
            // onBlur(); // Update user info when a new handle is added
          }} 
          variant="ghost" 
          className="text-gray-500 hover:text-gray-700 hover:bg-transparent p-0"
        >
          Add handle...
        </Button>
      )}
    </div>
  );
}

export default HandleInputs;
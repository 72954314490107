import React, { useState, useEffect } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Progress } from "./ui/progress";
import { HelpCircle, ChevronDown, ArrowLeft, X } from 'lucide-react';
import Sidebar from './sidemenu';
import { useHandles } from '../context/HandleContext';
import HandleInputs from './HandleInputs';
import Tooltip from './tooltip';
import { getUserInfo, updateUserInfo } from '../lib/apiCalls';
import { useParams } from 'react-router-dom';

export default function UserInfo() {
  const [isEditing, setIsEditing] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    professional_title: '',
    background: '',
    target_audience: '',
    testimonials: '',
    language: '',
    your_handles: [],
    related_handles: [],
    profile_completion: 0
  });

  const { handles, relatedHandles, addHandle, removeHandle, updateHandle, setHandles, setRelatedHandles } = useHandles();

  const {check} = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserInfo();
        if (check === 'true') {
          setIsEditing(true);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user');
          window.location.href = '/login';
        }
        console.error("Error fetching user info:", error);
      }
    };
  
    fetchData();
  }, [check]); 
  

  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo();
      console.log("user: ", response.data);

      if (response.success) {
        const { data } = response;
        setUserInfo(data);
        
        // Transform API handle format to frontend format
        if (data.your_handles) {
          setHandles(data.your_handles.map(handle => ({
            handle: handle.reference,
            platform: handle.type
          })));
        }

        if (data.related_handles) {
          setRelatedHandles(data.related_handles.map(handle => ({
            handle: handle.reference,
            platform: handle.type
          })));
        }
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Failed to fetch user info:', error);
    }
  };

  const toggleEdit = () => {
    if (isEditing) {
      handleSave();
    }
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    try {
      // Transform frontend handle format to API format
      const your_handles = handles.map(handle => ({
        type: handle.platform,
        reference: handle.handle
      }));

      const related_handles = relatedHandles.map(handle => ({
        type: handle.platform,
        reference: handle.handle
      }));

      const response = await updateUserInfo({
        ...userInfo,
        your_handles,
        related_handles
      });

      if (response.success) {
        fetchUserInfo(); // Refresh the data after successful update
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      console.error('Failed to update user info:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setUserInfo(prevInfo => ({ ...prevInfo, [field]: value }));
  };

  const LANGUAGES = [
    { code: 'en-US', name: 'English (US)' },
    { code: 'en-GB', name: 'English (UK)' },
    { code: 'es', name: 'Spanish (Español)' },
    { code: 'fr', name: 'French (Français)' },
    { code: 'de', name: 'German (Deutsch)' },
    { code: 'it', name: 'Italian (Italiano)' },
    { code: 'pt', name: 'Portuguese (Português)' },
    { code: 'ru', name: 'Russian (Русский)' },
    { code: 'zh', name: 'Chinese (中文)' },
    { code: 'ja', name: 'Japanese (日本語)' },
    { code: 'ko', name: 'Korean (한국어)' },
    { code: 'ar', name: 'Arabic (العربية)' },
    { code: 'hi', name: 'Hindi (हिन्दी)' },
    { code: 'bn', name: 'Bengali (বাংলা)' },
    { code: 'tr', name: 'Turkish (Türkçe)' },
    { code: 'nl', name: 'Dutch (Nederlands)' },
    { code: 'pl', name: 'Polish (Polski)' },
    { code: 'vi', name: 'Vietnamese (Tiếng Việt)' },
    { code: 'th', name: 'Thai (ไทย)' },
    { code: 'el', name: 'Greek (Ελληνικά)' },
    { code: 'he', name: 'Hebrew (עברית)' },
    { code: 'id', name: 'Indonesian (Bahasa Indonesia)' },
    { code: 'ms', name: 'Malay (Bahasa Melayu)' },
    { code: 'fa', name: 'Persian (فارسی)' },
    { code: 'ur', name: 'Urdu (اردو)' },
    { code: 'sv', name: 'Swedish (Svenska)' },
    { code: 'da', name: 'Danish (Dansk)' },
    { code: 'fi', name: 'Finnish (Suomi)' },
    { code: 'no', name: 'Norwegian (Norsk)' },
    { code: 'cs', name: 'Czech (Čeština)' },
    { code: 'hu', name: 'Hungarian (Magyar)' },
    { code: 'ro', name: 'Romanian (Română)' },
    { code: 'uk', name: 'Ukrainian (Українська)' },
    { code: 'hr', name: 'Croatian (Hrvatski)' },
    { code: 'sr', name: 'Serbian (Српски)' },
    { code: 'sk', name: 'Slovak (Slovenčina)' },
    { code: 'bg', name: 'Bulgarian (Български)' },
  ].sort((a, b) => a.name.localeCompare(b.name));

  const renderLanguageSelect = () => {
    // Add a default option if userInfo.language is not in LANGUAGES
    const currentLanguage = LANGUAGES.find(lang => lang.code === userInfo.language) || LANGUAGES[0];
    
    return (
      <div>
        <label className="flex items-center mb-2 font-medium gap-3">
          Language
          <Tooltip 
            content="Select your preferred language" 
            userGuidePath="www.google.com"
          />
        </label>
        <div className="relative">
          <select 
            value={currentLanguage.code}
            onChange={(e) => handleInputChange('language', e.target.value)}
            className="w-full p-2 border rounded-md appearance-none pr-10 bg-white"
            disabled={!isEditing}
          >
            {LANGUAGES.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
          <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>
    );
  };


  return (
    <div className="flex min-h-screen bg-[#FCFCFC] font-['Inter',sans-serif]">
      <Sidebar />
      <div className="flex-1 mt-6 w-full p-6 h-screen overflow-y-auto ml-[377px]"> 
        <div className="mx-auto rounded-xl">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center">
              {isEditing && <ArrowLeft className="mr-2 cursor-pointer" onClick={toggleEdit} />}
              <h1 className="text-2xl font-bold font-['Cormorant_Garamond',serif] ">{isEditing ? 'Edit User Info' : 'User Info'}</h1>
            </div>
            <Button 
              className={isEditing ? "bg-blue-600 hover:bg-blue-700 text-white px-8" : ""}
              onClick={toggleEdit}
            >
              {isEditing ? 'Save' : 'Edit Profile'}
            </Button>
          </div>
          
          <div className="flex gap-6">
            <div className="flex-1 space-y-6">
              <div className='bg-white p-6 rounded-xl'>
                <InputField 
                  label="Name or business name" 
                  value={userInfo.name} 
                  onChange={(value) => handleInputChange('name', value)} 
                  readOnly={!isEditing} 
                />
                <InputField 
                  label="Professional title" 
                  value={userInfo.professional_title} 
                  onChange={(value) => handleInputChange('professional_title', value)} 
                  readOnly={!isEditing} 
                />
                <TextareaField 
                  label="Background" 
                  value={userInfo.background} 
                  onChange={(value) => handleInputChange('background', value)} 
                  helpText={isEditing}
                  readOnly={!isEditing} 
                />
              </div>
              
              {!isEditing && (
                <div className='bg-white p-6 rounded-xl'>
                  <h3 className="text-lg font-semibold mb-2">Complete your profile</h3>
                  <div className="flex items-center">
                    <Progress 
                      value={userInfo.profile_completion} 
                      className="flex-grow mr-2 h-2 bg-gray-200" 
                      indicatorClassName="bg-gradient-to-r from-pink-500 to-purple-500"
                    />
                    <span>{userInfo.profile_completion}%</span>
                  </div>
                </div>
              )}
            </div>

            <div className="flex-[2] space-y-6 bg-white p-6 rounded-xl">
              <TextareaField 
                label="Target audience" 
                value={userInfo.target_audience} 
                onChange={(value) => handleInputChange('target_audience', value)}
                helpText={isEditing}
                readOnly={!isEditing} 
              />
              <TextareaField 
                label="Testimonials" 
                value={userInfo.testimonials} 
                onChange={(value) => handleInputChange('testimonials', value)}
                helpText={isEditing}
                readOnly={!isEditing} 
              />
              <HandleInputs 
                label="Your handles" 
                handles={handles} 
                addHandle={(handle, platform) => addHandle('handles', handle, platform)} 
                removeHandle={(index) => removeHandle('handles', index)} 
                updateHandle={(index, handle, platform) => updateHandle('handles', index, handle, platform)}
                isEditing={isEditing}
              />
              <HandleInputs 
                label="Related handles" 
                handles={relatedHandles} 
                addHandle={(handle, platform) => addHandle('relatedHandles', handle, platform)} 
                removeHandle={(index) => removeHandle('relatedHandles', index)} 
                updateHandle={(index, handle, platform) => updateHandle('relatedHandles', index, handle, platform)}
                isEditing={isEditing}
              />
              {renderLanguageSelect()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InputField({ label, value, onChange, readOnly }) {
  return (
    <div className='mt-2'>
      <div className="flex justify-start items-center mb-2 gap-3">
        <label className="font-medium">{label}</label>
        <Tooltip 
          content="tooltip testing" 
          userGuidePath="www.google.com"
        />
      </div>
      <Input value={value} onChange={(e) => onChange(e.target.value)} className="w-full" readOnly={readOnly} />
    </div>
  );
}

function TextareaField({ label, value, onChange, helpText, readOnly }) {
  return (
    <div className='mt-2'>
      <div className="flex justify-start mb-2 gap-2">
        <label className="font-medium">{label}</label>
        <Tooltip 
          content="tooltip testing" 
          userGuidePath="www.google.com"
        />
      </div>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-24 align-top text-start px-3 py-2 rounded-md border border-input bg-background text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        readOnly={readOnly}
      />
    </div>
  );
}
import React, { useState } from 'react';
import { Download, Copy, Edit, Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import { usePosts } from '../context/PostContext';
import ReactMarkdown from 'react-markdown';

const MarkdownComponents = {
  p: ({ children }) => (
    <p className="text-[#676767] text-[16px] leading-6">{children}</p>
  ),
  h1: ({ children }) => (
    <h1 className="text-xl font-bold mb-2 text-[#676767]">{children}</h1>
  ),
  h2: ({ children }) => (
    <h2 className="text-lg font-bold mb-2 text-[#676767]">{children}</h2>
  ),
  h3: ({ children }) => (
    <h3 className="text-md font-bold mb-2 text-[#676767]">{children}</h3>
  ),
  ul: ({ children }) => (
    <ul className="list-disc ml-4 mb-2 text-[#676767]">{children}</ul>
  ),
  ol: ({ children }) => (
    <ol className="list-decimal ml-4 mb-2 text-[#676767]">{children}</ol>
  ),
  li: ({ children }) => (
    <li className="mb-1 text-[16px]">{children}</li>
  ),
  code: ({ children }) => (
    <code className="bg-gray-100 rounded px-1 py-0.5 text-[14px]">{children}</code>
  ),
  pre: ({ children }) => (
    <pre className="bg-gray-100 rounded p-2 mb-2 overflow-x-auto text-[14px]">
      {children}
    </pre>
  ),
  blockquote: ({ children }) => (
    <blockquote className="border-l-4 border-gray-200 pl-4 italic my-2 text-[#676767]">
      {children}
    </blockquote>
  ),
  em: ({ children }) => (
    <em className="italic text-[#676767]">{children}</em>
  ),
  strong: ({ children }) => (
    <strong className="font-bold text-[#676767]">{children}</strong>
  ),
  a: ({ href, children }) => (
    <a href={href} className="text-purple-600 hover:text-purple-700 underline">
      {children}
    </a>
  ),
};

const PostCard = ({id, title, content, contentUrl, isNew }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { deletePost } = usePosts();

  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `${title}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      alert('Content copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleDelete = async() => {
    await deletePost(id);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="bg-white rounded-lg mt-6 border-[#F7F7F7] border max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-2 bg-[#FCFCFC] p-6">
        <h2 className="text-[14px] font-[500]">{title}</h2>
        {isNew && (
          <span className="bg-white border rounded-full px-6 py-3 text-purple-600 border-purple-600 text-xs font-bold">
            NEW
          </span>
        )}
      </div>
      
      <div className="relative px-6 pt-6">
        <div className={`prose prose-sm max-w-none ${(!isExpanded &&content.length > 160) ? "line-clamp-3" : ""}`}>
          <ReactMarkdown components={MarkdownComponents}>
            {String(content)}
          </ReactMarkdown>
        </div>
        {content.length > 160 && (
          <button
            onClick={toggleExpand}
            className="text-purple-600 text-sm font-semibold py-2 flex items-center hover:text-purple-700"
          >
            {isExpanded ? (
              <>Show less <ChevronUp className="ml-1 w-4 h-4" /></>
            ) : (
              <>See more <ChevronDown className="ml-1 w-4 h-4" /></>
            )}
          </button>
        )}
      </div>
      
      {contentUrl && (
        <div className="px-6 pt-4 flex w-full justify-center items-center overflow-hidden">
          <img 
            src={contentUrl} 
            alt="Generated content" 
            className="w-80 h-80 object-cover rounded-lg"
          />
        </div>
      )}
      
      <div className="flex justify-start p-6 space-x-4">
        <button
          onClick={handleDownload}
          className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
        >
          <Download size={20} />
        </button>
        <button
          onClick={handleCopy}
          className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
        >
          <Copy size={20} />
        </button>
        <button
          onClick={handleDelete}
          className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
        >
          <Trash2 size={20} />
        </button>
      </div>
    </div>
  );
};

export default PostCard;
import React, { createContext, useState, useContext, useEffect } from 'react';

const HandleContext = createContext();

export const HandleProvider = ({ children }) => {
  const [handles, setHandles] = useState(() => {
    const savedHandles = localStorage.getItem('handles');
    return savedHandles ? JSON.parse(savedHandles) : [
      { handle: '@example', platform: 'Instagram' },];
  });

  const [relatedHandles, setRelatedHandles] = useState(() => {
    const savedRelatedHandles = localStorage.getItem('relatedHandles');
    return savedRelatedHandles ? JSON.parse(savedRelatedHandles) : [
      { handle: '@example', platform: 'Instagram' },];
  });

  useEffect(() => {
    localStorage.setItem('handles', JSON.stringify(handles));
  }, [handles]);

  useEffect(() => {
    localStorage.setItem('relatedHandles', JSON.stringify(relatedHandles));
  }, [relatedHandles]);

  const addHandle = (type, newHandle, platform) => {
    const handleObj = { handle: newHandle, platform: platform };
    if (type === 'handles') {
      setHandles(prevHandles => [...prevHandles, handleObj]);
    } else {
      setRelatedHandles(prevRelatedHandles => [...prevRelatedHandles, handleObj]);
    }
  };

  // const setAllHandles = (type, newHandles) => {
  //   if (type === 'handles') {
  //     setHandles(newHandles);
  //   } else {
  //     setRelatedHandles(newHandles);
  //   }
  // };

  const removeHandle = (type, index) => {
    if (type === 'handles') {
      setHandles(prevHandles => prevHandles.filter((_, i) => i !== index));
    } else {
      setRelatedHandles(prevRelatedHandles => prevRelatedHandles.filter((_, i) => i !== index));
    }
  };

  const updateHandle = (type, index, newHandle, newPlatform) => {
    if (type === 'handles') {
      setHandles(prevHandles => {
        const newHandles = [...prevHandles];
        newHandles[index] = { handle: newHandle, platform: newPlatform };
        return newHandles;
      });
    } else {
      setRelatedHandles(prevRelatedHandles => {
        const newRelatedHandles = [...prevRelatedHandles];
        newRelatedHandles[index] = { handle: newHandle, platform: newPlatform };
        return newRelatedHandles;
      });
    }
  };

  return (
    <HandleContext.Provider value={{ handles, relatedHandles, addHandle, removeHandle, updateHandle, setHandles, setRelatedHandles }}>
      {children}
    </HandleContext.Provider>
  );
};

export const useHandles = () => useContext(HandleContext);
import { CircleX, Cross } from 'lucide-react';
import React from 'react';

const ErrorPopup = ({ message, link }) => (
  <div className="fixed top-4 right-4 bg-white rounded-lg shadow-lg p-4 z-50 flex items-center">
    <span className="text-red-500 mr-2"><CircleX /></span>
    <span>{message}</span>
    {link && (
      <a href="/wallet-recharge" className="ml-2 text-purple-600 underline">
        Recharge Now!
      </a>
    )}
  </div>
);

export default ErrorPopup;
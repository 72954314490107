import React, { useState, useEffect } from 'react';
import Sidebar from './sidemenu';
import { Search, Copy, X } from 'lucide-react';
import { getHowToUse } from '../lib/apiCalls'; // Assuming you have this function in your apiCalls file

const TabButton = ({ label, isActive, onClick }) => (
  <button
    className={`px-4 py-2 font-medium font-['Cormorant_Garamond',serif] ${
      isActive
        ? 'text-blue-600 border-b-2 border-blue-600'
        : 'text-gray-500 hover:text-gray-700'
    }`}
    onClick={onClick}
  >
    {label}
  </button>
);

const QuestionItem = ({ question, isActive, onClick }) => (
  <div
    className={`p-4 cursor-pointer border font-['Cormorant_Garamond',serif] rounded-lg border-[#F7F7F7] ${
      isActive ? 'bg-gray-100' : 'hover:bg-gray-50'
    }`}
    onClick={onClick}
  >
    {question}
  </div>
);

export default function HowToUse() {
  const [activeTab, setActiveTab] = useState('concept_generaion');
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [howToUseData, setHowToUseData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const tabs = {
    concept_generaion: 'Concept Generation',
    post_generaion: 'Post Generation',
    media_captioning: 'Media captioning'
  };

  useEffect(() => {
    const fetchHowToUseData = async () => {
      setIsLoading(true);
      try {
        const response = await getHowToUse();
        if (response.success) {
          setHowToUseData(response.htu);
          // Set the first question of the first tab as active
          const firstTab = Object.keys(response.htu)[0];
          if (response.htu[firstTab] && response.htu[firstTab].length > 0) {
            setActiveQuestion(response.htu[firstTab][0].question);
          }
        } else {
          setError('Failed to fetch how-to-use data.');
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHowToUseData();
  }, []);

  const filteredQuestions = howToUseData[activeTab]
    ? howToUseData[activeTab].filter(item =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const activeAnswer = activeQuestion
    ? howToUseData[activeTab].find(item => item.question === activeQuestion)?.answer
    : '';

  if (isLoading) {
    return (
      <div className="flex min-h-screen bg-[#FCFCFC]">
        <Sidebar />
        <div className="flex-1 p-8 ml-[380px] flex items-center justify-center">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex min-h-screen bg-[#FCFCFC]">
        <Sidebar />
        <div className="flex-1 p-8 ml-[380px] flex items-center justify-center">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-[#FCFCFC]">
      <Sidebar />
      <div className="flex-1 p-8 ml-[380px]">
        <h1 className="text-3xl font-bold mb-8 font-['Cormorant_Garamond',serif]">How to use</h1>
        <div className="mb-6 flex justify-between items-center">
          <div className="space-x-4">
            {Object.entries(tabs).map(([key, label]) => (
              <TabButton
                key={key}
                label={label}
                isActive={activeTab === key}
                onClick={() => {
                  setActiveTab(key);
                  setActiveQuestion(howToUseData[key][0]?.question || null);
                }}
              />
            ))}
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Find related questions..."
              className="pl-10 pr-4 py-2 border rounded-full w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
        </div>
        <div className="flex gap-8">
          <div className="w-1/3">
            <h2 className="text-xl font-semibold mb-4 font-['Cormorant_Garamond',serif]">Questions</h2>
            <div className="bg-white rounded-lg">
              {filteredQuestions.map((item) => (
                <QuestionItem
                  key={item.question}
                  question={item.question}
                  isActive={activeQuestion === item.question}
                  onClick={() => setActiveQuestion(item.question)}
                />
              ))}
            </div>
          </div>
          <div className="w-2/3">
            <div className="bg-white rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">{activeQuestion}</h2>
                <div className="flex space-x-2">
                  <button 
                    className="p-2 hover:bg-gray-100 rounded"
                    onClick={() => navigator.clipboard.writeText(activeAnswer)}
                  >
                    <Copy size={20} className="text-gray-500" />
                  </button>
                  <button 
                    className="p-2 hover:bg-gray-100 rounded"
                    onClick={() => setActiveQuestion(null)}
                  >
                    <X size={20} className="text-gray-500" />
                  </button>
                </div>
              </div>
              <p className="text-gray-700 whitespace-pre-line">
                {activeAnswer}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
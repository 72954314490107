import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { verifyRechargeSession } from '../../lib/apiCalls';

import { CheckCircle, XCircle } from 'lucide-react';

const RechargeSuccess = () => {
  const [verificationStatus, setVerificationStatus] = useState('loading');
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifySession = async () => {
      if (verificationAttempted.current) return;
      verificationAttempted.current = true;

      const sessionId = searchParams.get('session_id');
      if (!sessionId) {
        setVerificationStatus('error');
        setError('No session ID found. Please try the recharge process again.');
        return;
      }

      try {
        console.log('Verifying session with ID:', sessionId); // Debug log
        const response = await verifyRechargeSession(sessionId);
        console.log('Verification response:', response); // Debug log
        if (response.success) {
          setVerificationStatus('success');
        } else {
          setVerificationStatus('error');
          setError(response.message || 'Verification failed. Please try again.');
        }
      } catch (error) {
        console.error('Full error object:', error); // Debug log
        setVerificationStatus('error');
        setError(error.response?.data?.message || error.message || 'An error occurred during verification. Please try again.');
      }
    };

    verifySession();
  }, [searchParams]);

  const handleReturnHome = () => {
    navigate('/');  // Adjust this path as needed
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        {verificationStatus === 'loading' && (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500 mx-auto"></div>
            <p className="mt-4 text-lg">Verifying your recharge...</p>
          </div>
        )}

        {verificationStatus === 'success' && (
          <div className="text-center">
            <CheckCircle className="text-green-500 w-16 h-16 mx-auto" />
            <h2 className="mt-4 text-2xl font-bold text-green-600">Recharge Successful!</h2>
            <p className="mt-2 text-gray-600">Your wallet has been successfully recharged.</p>
          </div>
        )}

        {verificationStatus === 'error' && (
          <div className="text-center">
            <XCircle className="text-red-500 w-16 h-16 mx-auto" />
            <h2 className="mt-4 text-2xl font-bold text-red-600">Verification Failed</h2>
            <p className="mt-2 text-gray-600">{error}</p>
          </div>
        )}

        <button 
          onClick={handleReturnHome}
          className="mt-6 w-full bg-purple-600 text-white py-2 rounded-full hover:bg-purple-700 transition duration-300"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default RechargeSuccess;
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBJkQBy12O26TcPSzXRFUpgkPpmyvrE3ZM",
    authDomain: "fluffy-7d046.firebaseapp.com",
    projectId: "fluffy-7d046",
    storageBucket: "fluffy-7d046.appspot.com",
    messagingSenderId: "210155722969",
    appId: "1:210155722969:web:4a7f5c4d04c2bb4406c6c8",
    measurementId: "G-SJLHPZ6KTM"
};
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account"
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
